@import '00-settings/color';

/* stylelint-disable declaration-no-important */

// Base from https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css

/* stylelint-disable selector-max-universal */
* {
	&,
	&:before,
	&:after,
	&:first-letter,
	&:first-line {
		text-shadow: none !important;

		background: transparent !important;
		box-shadow: none !important;
	}

	&,
	.text-muted {
		color: $color-black !important; /* Black prints faster: h5bp.com/s */
	}
}
/* stylelint-enable selector-max-universal */

html {
	font-size: 0.62rem !important;
}

a {
	text-decoration: none !important;

	&:visited {
		text-decoration: underline !important;
	}

	&[href] {
		&:after {
			content: ' <' attr(href) '> ' !important;
		}
	}

	// Don't show for anchor or root links
	&[href^='#'],
	&[href^='/'] {
		&:after {
			content: '' !important;
		}
	}
}

abbr {
	&[title] {
		&:after {
			content: ' (' attr(title) ') ' !important;
		}
	}
}

pre,
blockquote {
	border: 1px solid $color-gray !important;
	page-break-inside: avoid !important;
}

thead {
	display: table-header-group !important; /* h5bp.com/t */
}

tr,
ol,
ul,
img {
	page-break-inside: avoid !important;
}

img {
	max-width: 100% !important;
}

p,
h2,
h3 {
	orphans: 3;
	widows: 3;
}

h1, h2, h3, h4, h5, h6 {
	color: $color-branding-1 !important;
	font-weight: bold !important;
	page-break-after: avoid;
}

h1 {
	font-size: 2rem !important;
}

h2, h3, h4, h5, h6 {
	font-size: 1.5rem !important;
}

small {
	font-size: 1rem !important;
}


// Disabled elements
.btn,
.header__search,
.header__nav,
.js-selector,
.footer {
	display: none !important;
}

.header__logo {
	img {
		width: 100px !important;
		max-height: none !important;
	}
}

.container,
.header__container {
	width: 100% !important;
	max-width: none !important;
	margin: 0 auto !important;
}

.card {
	display: block;

	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	border-radius: 0 !important;
	border-color: $color-black !important;

	page-break-inside: avoid !important;

	&__header,
	&__body {
		padding: 1rem 0 !important;
	}
}


//custom elements
.meta-list {
	border-right: none !important;
	border-left: none !important;
	border-radius: 0 !important;
	border-color: $color-black !important;
}

.meta__heading {
	font-size: 1rem !important;
}

.meta__value {
	color: $color-black !important;
	font-size: 2rem !important;
}

.meta__value--s {
	color: $color-black !important;
	font-size: 1rem !important;
}

.meta-list__item,
.meta__heading,
.meta__value,
.meta__value--l {
	border-color: $color-white !important;

	&:after {
		border-color: $color-white !important;
	}
}

.page-break {
	page-break-before: always;
}
